<template>
    <div class="page">
        <Aside></Aside>
        <div class="content">
            <div class="content-box">
                <div class="content-header">平台配置设置</div>
                <div class="content-detail">
                    <a-form-model ref="form" :model="form" :rules="formRules" size="small">
                        <a-form-model-item label="平台名称" prop="platform_name">
                            <a-input v-model="form.platform_name" :maxLength="32" style="width:500px"/>
                        </a-form-model-item>
                        <a-form-model-item label="设置平台LOGO" class="logo-box" :required="true">
                            <div class="img-box" :style="{'background-image':`url(${logoImg})`}" ></div>
                            <a-button class="btn" type="primary" ghost @click="showModal">添加LOGO</a-button>
                        </a-form-model-item>
                    </a-form-model>
                </div>
                <div class="content-btn">
                    <a-button type="primary" size="small" class="confirm-btn btn" @click.native="editPlatformData" :disabled="isDisable">确认</a-button>
                    <a-button size="small" class="cancel-btn btn" @click.native="$router.push('/')">取消</a-button>
                </div>
            </div>
        </div>
        <!-- 图片裁剪 -->
        <a-modal :destroyOnClose="true" v-model:visible="visible" title="设置新LOGO" :width="508" :bodyStyle="bodyStyle" @ok="saveAvatar" @cancel="cancel">
            <div class="tailoring-content">
                <div class="preview-box-parcel">
                    <div class="title">LOGO预览</div>
                    <div class="before" v-if="imgUrl"></div>
                    <div v-else>
                        <img src="@/assets/images/small_logo.png" />
                    </div>
                </div>
                <div class="container">
                    <div class="title">编辑图片</div>
                    <div class="img-container" v-if="imgUrl">
                        <img :src="imgUrl" ref="image" alt=""> 
                    </div>
                    <div class="noImg-box big" v-else>
                        <img src="@/assets/images/avater_default.png" />
                    </div>
                </div>
            </div>
            <div class="button-box">
                <div>
                    <a-button type="primary" size="small" ghost @click="resetCrop">复位</a-button>
                    <a-button type="primary" style='margin:0 16px;' size="small" ghost @click="imgUrl && cropper.rotate(45)">旋转</a-button>
                    <a-button type="primary" size="small" ghost @click="scaleCrop">转向</a-button>
                </div>
                <div>
                    <a-upload
                        class="elupload"
                        ref="upload"
                        :before-upload="handleBeforeUpload"
                        :accept="accept"
                        action="">
                        <a-button type="primary" size="small">上传本地图片</a-button>
                    </a-upload>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import Aside from "./components/aside";
import {editPlatformInfo,getPlatformInfo} from "@/api/data.js";
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
export default {
    components:{Aside},
    data(){
        return {
            form:{
                platform_name:'',
            },
            formRules:{
                platform_name:[{required: true, message: '请输入平台名称', trigger: 'blur' }]
            },
            logoImg:'',
            accept:'.jpg,.jpeg,.png',
            visible:false,
            cropper:'',
            bodyStyle:{
                padding:'0 24px 21px 24px',
            },
            imgUrl:'',
            flagY: false, //裁剪的图片是否反转，默认false(不反转)
            isDisable:false,
            oldLogoImg:'',
            platformName:'',
        }
    },
    mounted(){
        this.getPlatformData();
    },
    methods:{
        getPlatformData(){
            getPlatformInfo().then(res => {
                this.$store.commit('setPlatformData',res.data);
                let img = res.data.logo_url || require('@/assets/images/_logo.png');
                let image = new Image();
                // 解决跨域问题
                image.setAttribute('crossOrigin', 'anonymous');
                image.src = img + '?v=' + new Date().getTime();
                image.onload = () => {
                    this.logoImg = this.$getBase64Image(image);
                    this.oldLogoImg = this.$getBase64Image(image);
                }
                this.form.platform_name = res.data.platform_name;
                this.platformName = res.data.platform_name;
            })
        },
        showModal(){
            this.visible = true;
            this.flagY = false;
            this.imgUrl = '';
        },
        // 重置
        resetCrop(){
            if(this.imgUrl){
                this.flagY = false;
                this.cropper.reset();
            }
        },
        // 反转
        scaleCrop() {
            if(this.imgUrl){
                if (this.flagY) {
                    this.cropper.scaleY(1), (this.flagY = false);
                } else {
                    this.cropper.scaleY(-1), (this.flagY = true);
                }
            }
        },
        init(){
            this.cropper = new Cropper(this.$refs.image, {
                viewMode: 1,
                dragMode: 'none',
                initialAspectRatio: 1,
                aspectRatio: 1,
                preview: '.before',
                autoCropArea: 0.9,
                zoomOnWheel: false,
            })
        },
        handleBeforeUpload(file){
            let acceptList = ["png","jpeg","jpg"];
            let fileType = file.name.split('.').pop().toLowerCase();
            if (acceptList.indexOf(fileType) === -1) {
                this.$message.warning('只支持上传jpg、Png、Jpeg格式');
                return false
            }
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = e => {
                this.imgUrl = e.target.result;
                this.flagY = false;
                if(this.cropper){ 
                    this.cropper.replace(this.imgUrl); 
                }else{
                    this.$nextTick(() => {
                        this.init();
                    })
                }
            }
            return false // 阻止Upload的默认上传
        },
        saveAvatar(){
            if(this.imgUrl){
                this.logoImg = this.cropper.getCroppedCanvas().toDataURL('image/png');
                this.cancel();
            }
        },
        cancel(){
            //销毁裁剪对象
            this.cropper.destroy()
            this.cropper = null
            this.visible = false;
        },
        editPlatformData(){
            this.$refs.form.validate((valid) => {
                if(valid){
                    this.isDisable = true;
                    if(this.logoImg === this.oldLogoImg && this.form.platform_name === this.platformName){
                        this.$message.warning('没有进行任何修改');
                        return 
                    }
                    editPlatformInfo({platform_name:this.form.platform_name,img_base64:this.logoImg}).then(res => {
                        this.isDisable = false;
                        if(res.code === 1){
                            this.$message.success('平台配置设置成功');
                            this.getPlatformData();
                        }
                    }).catch(() => {
                        this.isDisable = false;
                    })
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.content-detail{
    ::v-deep .ant-form-item{
        display: flex;
        .ant-form-item-label{
            padding:0;
            color:#515151;
            width:108px;
        }
        .ant-form-item-control-wrapper{
            margin-left:80px !important;
        }
    } 
    .form-bottom{
        padding-top:30px;
        border-top:1px solid #F3F3F3;
    }
    .txt{
        width:260px;
        font-size: 14px;
        line-height: 34px;
        height:34px;
        background: #F7F9FA;
        border-radius: 4px;
        color:#515151;
        padding-left:20px;
    }
    .logo-box{
        ::v-deep .ant-form-item-children{
            display: flex;
            align-items: center;
        }
        .img-box{
            width:80px;
            height:80px;
            background: #fff;
            box-shadow: 0px 1px 4px rgba(8, 35, 48, 0.24);
            border-radius: 10px;
            background-size: cover;
        }
        .btn{
            margin-left:40px;
        }
    }
}
.tailoring-content{
  display: flex;
  .title{
      font-size:14px;
      line-height: 56px;
      color:#8C8C8C;
      margin-bottom:4px;
  }
}
.preview-box-parcel{
    width:140px;
}
.before{
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 100%;
}
.img-container{
    width: 320px;
    height: 260px;
    overflow: hidden;
}
.noImg-box{
    width: 320px;
    height: 260px;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}
.button-box{
    margin-left:140px;
    margin-top:20px;
    display: flex;
    justify-content: space-between;
}
::v-deep .ant-upload-list{
    display: none !important;
}
.content-btn{
    margin:50px 0 0 185px;
    .btn{
        padding:0 30px;
        font-size: 14px;
        line-height: 34px;
        height:34px;
        border-radius: 4px;
    }
    .confirm-btn{
        background:#367BF5;
    }
    .cancel-btn{
        background: #6B6B6B;
        color: #EDEDED;
        margin-left:50px;
    }
}
</style>